import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jxie/projects/lyra/packages/web/src/components/content/ContentPage.tsx";
export const activityKeys = ['textRevealAsk', 'textRevealShow', 'textRevealPatient', 'textRevealReassuring', 'textRevealMonitor'];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Grid = makeShortcode("Grid");
const TextReveal = makeShortcode("TextReveal");
const layoutProps = {
  activityKeys,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "supporting-them-during-the-pandemic"
    }}>{`Supporting them during the pandemic`}</h2>
    <p>{`It’s hard enough for most adults to cope with the challenges thrown at them during the pandemic. Young people need extra guidance and support from the adults around them. Remember, teenagers are not only facing the pandemic `}{`—`}{` they are also navigating all of the normal challenges of adolescence at the same time.`}</p>
    <p><strong parentName="p">{`So how can you support them?`}</strong></p>
    <p>{`Here are some tips for providing emotional support to your teen during the pandemic:`}</p>
    <Grid container spacing={3} mdxType="Grid">
  <Grid item xs={12} mdxType="Grid">
    <TextReveal id="textRevealAsk" header="Ask, don't assume." mdxType="TextReveal">
      <p>
        Even at this stage of the pandemic, remember to ask your teen how they are feeling and how they are coping,
        especially as the situation changes. Let them know that you are there for them, whenever they want to talk or
        have questions. When they do open up to you, focus on listening &mdash; what they need most from you is empathy,
        compassion, and comfort.{' '}
      </p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} mdxType="Grid">
    <TextReveal id="textRevealShow" header="Show them how you do it." mdxType="TextReveal">
      <p>
        Your teen will be looking to you to gauge how worried they should be, and how to respond. Try to set a good
        example for your teen by appearing as calm as you can. Also, be a good role model by using healthy coping
        strategies yourself.{' '}
      </p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} mdxType="Grid">
    <TextReveal id="textRevealPatient" header="Be patient." mdxType="TextReveal">
      <p>
        Although it might be hard, you may need to cut them some slack during the pandemic. Try to be extra patient with
        your teen during this time.{' '}
      </p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} mdxType="Grid">
    <TextReveal id="textRevealReassuring" header="Be reassuring, but realistic." mdxType="TextReveal">
      <p>
        Amidst all of the negative news they may be exposed to, your teen needs your reassurance that your family will
        get through the pandemic together, and that things will improve over time. However, be careful not to make{' '}
        <i>unrealistic</i> promises &mdash; for example, that a lockdown will end by a certain time, or that they will
        be able to celebrate milestones in person (if this is not certain).
      </p>
    </TextReveal>
  </Grid>
  <Grid item xs={12} mdxType="Grid">
    <TextReveal id="textRevealMonitor" header="Monitor." mdxType="TextReveal">
      <p>
        Keep an eye on your teen’s stress levels &mdash; look out for changes in their behaviour, health, and how
        they’re thinking and feeling. Encourage them to do things that have helped them to cope with stressful times in
        the past.
      </p>
    </TextReveal>
  </Grid>
    </Grid>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      